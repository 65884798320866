.clientDetailsIcon {
    font-size: 2.4rem !important;
    margin-right: 8px;
}

/* td {
    min-width: 250px !important;
} */

/* td:last-child {
    text-align: right;
} */

.clientTableHolder {
    padding: 16px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.clientTableHeader {
    padding-bottom: 0px !important;
    justify-content: space-between;
}

.closeButton {
    margin: 8px !important;
}