.tableRow {
    cursor: pointer;
}

.link:hover {
    color: #6CA4CB !important;
}

.active, .tableRow:hover {
    background-color: #F5F5F5 !important;
}

.image {
    width: 80px;
    margin-right: 8px;
}

.regLink {
    display: flex;
    align-items: center;
}
.registrationTd {
    display: flex;
}
.registrationText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
}