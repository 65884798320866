.marker-cluster div {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    text-shadow:
    1px  1px 1px black,
    1px -1px 1px black,
   -1px  1px 1px black,
   -1px -1px 1px black;
   color: white;
}
.marker-cluster-small {
    background-color: #6CA4CB;
}

.marker-cluster-small div {
    background-color: #4174E0;
}
.marker-cluster-medium {
    background-color: #6CA4CB;
}

.marker-cluster-medium div {
    background-color: #4174E0;
}
.marker-cluster-large {
    background-color: #6CA4CB;
}

.marker-cluster-large div {
    background-color: #4174E0;
}

.markerIcon {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}