.batteryGauge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 16px;
}

.batteryContainer {
    display: flex;
    justify-content: center;
}

.battery {
    border: 5px solid rgba(0, 0, 0, 0.54);
    overflow: hidden;
    height: 90px;
    width: 180px;
    border-radius: 6px;
    display: flex;
    flex-direction: column-reverse;
    padding: 4px;
}

.juice {
    transition: all 4s;
    width: 100%;
    background-color: rgba(0, 185, 85, 0.87);
    height: 100%;
    z-index: 0;
    border-radius: 2px;
    background-position: center;
}

.juiceSlow {
    transition: width 30s;
    width: 100%;
    background-color: rgba(0, 185, 85, 0.87);
    height: 100%;
    z-index: 0;
    border-radius: 2px;
    background-position: center;
}


.juiceDown {
    background-color: rgba(0, 185, 85, 0.87);
    background-size: 100%;
    transition: all 4s;
}

.juiceOrange {
    transition: all 4s;
    width: 100%;
    background-color: #FF9800;
    height: 100%;
    z-index: 0;
    border-radius: 2px;
    background-position: center;
}

.juiceOrangeSlow {
    transition: width 30s;
    width: 100%;
    background-color: #FF9800;
    height: 100%;
    z-index: 0;
    border-radius: 2px;
    background-position: center;
}

.juiceDownOrange {
    background-color: #FF9800;
    background-size: 100%;
    transition: all 4s;
}

.juiceRed {
    transition: all 4s;
    width: 100%;
    background-color: #F44336;
    height: 100%;
    z-index: 0;
    border-radius: 2px;
    background-position: center;
}

.juiceRedSlow {
    transition: width 30s;
    width: 100%;
    background-color: #F44336;
    height: 100%;
    z-index: 0;
    border-radius: 2px;
    background-position: center;
}

.juiceDownRed {
    background-color: #F44336;
    background-size: 100%;
    transition: all 4s;
}

.orange {
    color: #FF9800;
}

.red {
    color: #F44336;
}

.green {
    color: rgba(0, 185, 85, 0.87);
}

.blue {
    color: #44799E;
}

.plusContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.plus {
    width: 8px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    height: 35%;
    background-color: rgba(0, 0, 0, 0.54);
}

.whiteNumber {
    transition: all 4s;
    width: 100%;
    text-align: center;
    z-index: 1;
    color: white;
    margin-top: 25px;
}

.w10 {
    margin-left: 70px;
    color: rgba(0, 0, 0, 0.54);
}

.description {
    margin-top: 10px;
    transition: all 4s;
}

.description span {
    transition: all 4s;
}

.description svg {
    transition: all 4s;
}

.boltIcon {
    font-size: 16px !important;
    margin-top: 0px;
    margin-bottom: -3px;
    margin-right: 5px;
}