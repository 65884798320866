.buttonHolder{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 25px;
}
.buttonHolder>button {
    height: 35vh;
    margin-top: 25px;
    width: 45%;
    margin: 1%;
}

.buttonHolder>.charge {
    width: 92%;
    margin: 2%;
}