.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #44799E;
}

a {
  color: #44799E;
  text-decoration: inherit;
}

a:active {
  color: #6CA4CB;
}

html {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 2 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: calc(11px + 2 * ((100vw - 320px) / 680));
  }
}
