#container {
    overflow: hidden;
}

#stripes {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-transform: skewY(-15deg);
    transform: skewY(-15deg);
    -webkit-transform-origin: 0;
    transform-origin: 0;
    background: linear-gradient(150deg,#4174E0 15%,#41C8E0 70%,#3ED6C4 94%);
}

#stripes, #stripes span {
    position: absolute;
}

#stripes span {
    height: 300px;
}

#stripes :first-child {
    width: 33.33333%;
    width: calc(100% / 3);
    left: -16.66666%;
    left: calc(calc(calc(100% / 3) / 2) * -1);
    background: #4174E0;
}

#stripes :nth-child(2) {
    /* width: 33.33333%; */
    width: calc(100% / 3);
    top: 0;
    left: 16.66666%;
    left: calc(calc(100% / 3) / 2);
    right: auto;
    background: #6CA4CB;
}

#stripes :nth-child(3) {
    width: 33.33333%;
    width: calc(100% / 3);
    left: 49.99999%;
    left: calc(calc(calc(100% / 3) / 2) + calc(100% / 3));
    bottom: auto;
    background: #41C8E0;
}

#stripes :nth-child(4) {
    width: 33.33333%;
    width: calc(100% / 3);
    top: 380px;
    top: calc(190px * 2);
    right: -16.66666%;
    right: calc(calc(calc(100% / 3) / 2) * -1);
    background: #6CA4CB;
}

#stripes :nth-child(5) {
    width: 33.33333%;
    width: calc(100% / 3);
    bottom: 0;
    background: #1fa2ff;
}

#ipad img {
    max-width: 600px;
}

#ipad {
    position: relative;
    margin-top: -20.6vh;
    transform: rotate(-15deg);
}

#h2 {
 font-size: calc(46px + 8 * ((100vw - 320px) / 680))
}

#h6 {
 font-size: calc(16px + 4 * ((100vw - 320px) / 680))
}