.singleUnitSnack {
    margin-top: 48px;
    margin-right: 80px;
    z-index: 1 !important;
}

.minor {
    background-color: #FDD835 !important;
}

.major {
    background-color: #FFA000 !important;
}

.critical {
    background-color: #D32F2F !important;
}

.singleMap {
    width: '100%';
    border-radius: 4px;
    z-index: 0;
    opacity: 1;
}

.singleMapPadded {
    width: '100%';
    border-radius: 5px;
    z-index: 0;
    opacity: 1;
    margin-top: 0px
}

.scrollOverlay:hover::before {
    content: 'Click + scroll to zoom the map';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 34px;
    z-index: 999;
    height: inherit;
    background-color: #5a525261;
    opacity: 0.5;
    text-shadow: 1px 1px 0px rgba(255,255,255,0.6),
                 2px 2px 0px rgba(0, 0, 0, 0.5);
}

.scrollOverlay:hover::after {
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    content: '';
    z-index: 999;
}

.closeIcon {
    cursor: pointer;
    margin-bottom: -7px;
    margin-left: 15px;
}

.historyContainer {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    padding-bottom: 12px;
}

.slider {
    margin-top: 6px;
    margin-left: 32px;
    margin-right: 16px;
    width: 67.5% !important;
}

.playButton,.pauseButton, .skipButton {
    color: #6CA4CB !important;
}

.disabledButton {
    color: lightgrey !important;
}

.historyContainer > button {
    border-radius: 2px;
}