.selected li {
  background-color: white;
}

.selected li:hover {
  background-color: rgba(255,255,255,0.8);
}

.selected div {
  color: black;
}

.navBarRight {
    height: 65px;
    border-left: 1px solid lightgrey;
    margin-top: -15px;
    margin-bottom: -15px;
    margin-left: 25px;
    margin-right: 25px;
}